/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        //add simple support for background images:
        document.addEventListener('lazybeforeunveil', function(e){
          var bg = e.target.getAttribute('data-bg');
          if(bg){
              e.target.style.backgroundImage = 'url(' + bg + ')';
          }
        });

        // Hamburger menu
          var hamburger = $('#toggle');
          
          hamburger.click(function() {

            if ( hamburger.hasClass('active') ){
              // Do all the stuff to close it

              $('body').removeAttr("style");
              $('.scrollbar-measure').remove();
              hamburger.removeClass('active');
              hamburger.attr("aria-expanded","false");
              $('#overlay').removeClass('open');
              $('body').removeClass('js-noscroll');
            } else {
              // Do all the stuff to open it
              hamburger.addClass('active');
              hamburger.attr("aria-expanded","true");
              $('#overlay').addClass('open');
              $('body').addClass('js-noscroll');

               // Detect Scrollbar Width
                // Create the measurement node
                var scrollDiv = document.createElement("div");
                scrollDiv.className = "scrollbar-measure";
                document.body.appendChild(scrollDiv);

                // Get the scrollbar width
                var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
                console.warn(scrollbarWidth); // Mac:  15


                $('body').css('padding-right', scrollbarWidth);
            }

             return false;
          });


          // Hero Slider
          $('.hero-slider').slick({
          dots: false,
          autoplay: true,
          speed: 1500,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1
          });

          // CTA Section Anchor
          var containerScroll = $("html,body");
          var scrollTo = $('.section-block');
          $( '.scroll-js' ).click( function() {
            $( this ).toggleClass( 'active' );
            $(containerScroll).animate({scrollTop: $(scrollTo).offset().top}, 800);
            return false;
          });

          // Gallery
          $('.gallery').lightGallery({
            thumbnail:true,
            download: false,
            mode: 'lg-fade',
            selector: $('.gallery__img')
          });

          // Back to top
          $( window ).scroll( function() {
            if ( $( this ).scrollTop() > 800 ) {
              $( '.back-to-top' ).addClass( 'active' );
            } else {
              $( '.back-to-top' ).removeClass( 'active' );
            }
          });

          // Click event to scroll to top.
          $( '.back-to-top' ).click( function() {
            $( 'html, body' ).animate( { scrollTop : 0 }, 800 );
            return false;
          });

          // Clickable container

          $('.clickable').click(function() {
            window.location = $(this).find("a").attr("href"); 
            return false;
          });

         

          // faqs
          // $(".faqs-list__question").click( function () {
          //   var container = $(this).parents(".faqs-list__item");
          //   var answer = container.find(".faqs-list__answer");
          //   var trigger = container.find(".faqs-list__icon");

          //   answer.slideToggle(200);

          //   if (trigger.hasClass("faqs-list__icon-active")) {
          //     trigger.removeClass("faqs-list__icon-active");
          //   }
          //   else {
          //     trigger.addClass("faqs-list__icon-active");
          //   }
          // });


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // Stats counter
        $('.stat__points').counterUp({
          delay: 10,
          time: 400
      });

      }
    },
    // The team page, note the change from the-team to the_team.
    'the_team': {
      init: function() {
        // JavaScript to be fired on the about us page

         // Modal card trigger

          // instanciate new modal
          var modal = new tingle.modal({
            footer: true,
            stickyFooter: false,
            closeMethods: ['overlay', 'button', 'escape'],
            closeLabel: "Close",
            cssClass: ['custom-class-1', 'custom-class-2'],
            onOpen: function() {
                console.log('modal open');
            },
            onClose: function() {
                console.log('modal closed');
            },
            beforeClose: function() {
                // here's goes some logic
                // e.g. save content before closing the modal
                return true; // close the modal
                return false; // nothing happens
            }
        });

          // set content
     
          modal.setContent(document.querySelector('.js-modal').innerHTML);
            

          // add button
          modal.addFooterBtn('Close', 'button--blue button', function() {
            // here goes some logic
            modal.close();
          });

          // open modal
          //modal.open();

          // close modal
          //modal.close();

          $('.js-modal-trigger').click(function(event) {
            modal.open();
          });



      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
